import React from "react";
import "./Services.css";
import Emoji from "../../imgs/emoji - Copy.jpg";
import Card from "../cards/Card";
import resume from "./neetu_kumari.docx";
import {motion} from 'framer-motion';
const Services = () => {
  const transition={duration:1,type:'spring'}
  return (
    <div className="services" id="services">
      {/* left side */}
      <div className="awesome">
        <span>My Awesome</span>
        <span>services</span>
        {/* <span style={{fontFamily:'cursive' }}>
        I work to create innovative solutions that inspire, and foster memorable relationships between brands and their clients. With a focus on branding and UI / Web, I strive to create usable and polished products through passionate and deliberate design.</span>
 
  */}
        <span  style={{ fontFamily: "system-ui" }}>
        I work to create innovative solutions that inspire, and foster memorable<br></br>  
        relationships between brands and their clients. With a focus on branding<br></br>
        and UI / Web, I strive to create usable and polished products through<br></br>
        passionate and deliberate design. 
 
        {/* I create websites with attention to detail and a rendering faithful to the design.          <br /> */}
                 </span>
        <a href={resume} download>
        <button className="button s-button">Download CV</button>
        </a>
        <div className="blur s-blur1" style={{ background: "var(--purple)" }}></div>
      </div>

      {/* right side */}

      <div className="cards">

        <motion.div
        whileInView={{left:'14rem'}}
        initial={{left:'20rem'}}
        transition={transition}
         style={{left
        :'14rem'}}>
 
          <Card
            emoji = {Emoji}
            heading = {'Developer'}
            detail = {'React'}
          />
        </motion.div>
        {/* second card */}

<motion.div
        whileInView={{left:'0rem'}}
        initial={{left:'9rem'}}
        transition={transition}
 style={{top:"12rem",left
:"-4rem"}}>
  <Card
    emoji = {Emoji}
            heading = {'Developer'}
            detail = {'Angular'}
  />
</motion.div>
        {/* Third card */}
        <motion.div
        whileInView={{left:'19rem'}}
        initial={{left:'5rem'}}
        transition={transition}
         style={{top:"19rem",left
:"12rem"}}>
          <Card
             emoji = {Emoji}
            heading = {'Application programming interface'}
            detail = {'Rest API'}
  
          />
        </motion.div>
      </div>
    </div>
  );
};

export default Services;
