import React from "react";
import "./Experience.css";

const Experience = () => {
  return (
    <div className="experience" id="experience">
      <div className="achievement">
        <div className="circle">2</div>
        <span>Years </span>
        <span>Experience</span>
      </div>
      <div className="achievement">
        <div className="circle">10+</div>
        <span>Completed </span>
        <span>Projects</span>
      </div>
      <div className="achievement">
        <div className="circle">1</div>
        <span>Company </span>
        <span>work</span>
      </div>
    </div>
  );
};
export default Experience;
