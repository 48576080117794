import React from "react";
import "./Intro.css";
import Me from "../../imgs/me.png";
//import Vector from '../../imgs/vector.jpg';
//import Vector3 from '../../imgs/vector3.jpg';
//import Vecc from '../../imgs/vector2 - Copy.jpg';
import Vecco from "../../imgs/vec.jpg";

const Intro = () => {
  return (
    <div className="intro">
      <div className="i-left">
        <div className="i-name">
          <span>HELLO ! I Am </span>
          <span>Neetu Kumari </span>
          <span style={{ fontFamily: "cursive", color: "red" }}>
            Creative, Passionate, Focused
          </span>
          <br></br>
          
          <span style={{ fontFamily: "system-ui" }}>
            Currently I'm a freelance designer and web developer, who works with
            a variety of clients and on many diverse projects.
          </span>
          <br></br>
        </div>
        <button className="button i-button">Hire me</button>
      </div>
      <div className="i-right">
        <img src={Vecco} alt="" />
        <img src={Me} alt="" />
      </div>
    </div>
  );
};

export default Intro;
